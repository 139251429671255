import React from "react"

import Layout from "../components/0_utilities/layout"
import SEO from "../components/0_utilities/seo"

import AboutImg from "../components/1_atoms/banner/img/img-about"

const IndexPage = () => (
  <Layout image={<AboutImg />} pageTitle="About Jason Klinke">
    <SEO
      title="About Jason Klinke"
      description="Over the past 19 years, I have serviced pianos in the Philadelphia and
      South Jersey area for the general public, as well as schools, churches,
      colleges and universities including Rowan, Stockton, Rutgers and Temple."
    />
    <p>
      I come from a long line of woodworkers; building and fixing things has
      always been a natural part of my life. For my fifth birthday, my father
      made me a wooden toolbox and filled it with hand tools. That simple gift
      ignited my passion for working with my hands and learning woodworking from
      my father fostered it even further. By age ten, I discovered my love of
      music and began taking guitar and piano lessons. It wasn’t long before I
      began to merge my interest in music and my natural inclination to fix
      things.
    </p>

    <p>
      At the age of 18 I began working as an apprentice at Marquez Pianos
      learning the business of piano tuning, repair, and rebuilding while
      attending Temple University’s Boyer College of Music. At Temple, I studied
      Jazz guitar with Tom Giacobetti and became part of a community of
      musicians. I graduated with a Bachelor’s degree in Music, and have been
      working full time as a piano tuner/technician and professional musician
      ever since. The combination of my background as both a performer and a
      technician gives me a more complete understanding of my clients’ needs.
    </p>

    <p>
      Over the past 19 years, I have serviced pianos in the Philadelphia and
      South Jersey area for the general public, as well as schools, churches,
      colleges and universities including Rowan, Stockton, Rutgers and Temple. I
      have also tuned for many professional musicians including international
      artists like Tony Bennett, Arlo Guthrie, The Piano Guys, Brandford
      Marsalis, Dr. John, Fred Hersch, Roseanne Cash, Kenny Barron, Frankie
      Avalon and Bobby Rydell. I pride myself on growing as a professional and
      have continued my education through books, videos, and mentorship by top
      piano industry folks. I am blessed to have some great and truly inspiring
      mentors. Through my continued mentorship with the Trefz piano family, I
      have access to a wealth of knowledge gained over the last 185 years of
      their family involvement in the piano business.
    </p>

    <p>
      I reside in NJ with my amazing family and continue my career as an
      independent piano technician and musician. I am a certified climate
      control specialist and installer of the Dampp-Chaser Piano Life Saver
      System and a registered member of the Master Piano Technicians of America.
      With nearly twenty years of experience in the piano service business, my
      enthusiasm for this work has only grown. My philosophy is that no matter
      how good you are, there is always room for improvement. This personal
      belief pushes me to pursue excellence in all I do for each and every
      client and their unique piano needs.
    </p>
  </Layout>
)

export default IndexPage
